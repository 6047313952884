import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { menuArray } from "../../utils/service-type-menu";

const ServiceSidebar = ({
  type = "",
  activeMenu = "",
  onMenuClick = () => {},
}) => {
  const [menus, setMenus] = useState([]);

  useEffect(() => {
    if (type) {
      const selectedType = menuArray.find((item) => item.type === type);
      setMenus(selectedType?.menus || []);
    }
  }, [type]);

  return (
    <div className="services-details-info">
      <ul className="services-list">
        {menus.length > 0 ? (
          menus.map((menuItem) => (
            <li
              key={menuItem.name}
              className={activeMenu === menuItem.name ? "active" : ""}
            >
              <Link
                to={menuItem.slug}
                onClick={() => {
                  onMenuClick(menuItem);
                }}
                className={activeMenu === menuItem.name ? "active-link" : ""}
              >
                {menuItem.name}
              </Link>
            </li>
          ))
        ) : (
          <li>No services available</li>
        )}
      </ul>

      <style jsx>{`
        .services-list li a {
          display: block;
          padding: 1rem;
          color: #666;
          border-bottom: 1px solid #eee;
          transition: all 0.3s ease;
        }

        .services-list li a:hover,
        .services-list li a.active-link {
          color: #44ce6f;
          background-color: #f8f8f8;
          padding-left: 1.25rem;
        }

        .services-list li.active a {
          color: #44ce6f;
          background-color: #f8f8f8;
        }
      `}</style>
    </div>
  );
};

export default ServiceSidebar;