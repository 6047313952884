import React from "react";
import starIcon from "../../images/star-icon.png";
import howItWork from "../../images/how-its-work.png";

const WhyChooseUs = () => {
  return (
    <>
      <section className="how-its-work-area ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="how-its-work-content">
                <span className="sub-title">
                  <img src={starIcon} alt="banner" />
                  People Love Us
                </span>
                <h2>Why Choose Us?</h2>
                <p>
                  We differentiate ourselves through our customized solutions, a
                  highly skilled team, and a strong focus on user experience.
                  Our agile methodology guarantees timely delivery, while clear
                  and open communication ensures our clients are well-informed
                  at every stage of the project.
                </p>
                <div className="inner-box">
                  <div className="single-item">
                    <div className="count-box">1</div>
                    <h3>Planning</h3>
                    <p>
                      "We work with you to define the software’s architecture,
                      design user-friendly interfaces, and build scalable
                      systems. We also plan resources, set timelines, and assess
                      risks for smooth execution."
                    </p>
                  </div>
                  <div className="single-item">
                    <div className="count-box">2</div>
                    <h3>Development and Coding</h3>
                    <p>
                      "In this phase, our team writes clean, efficient code to
                      bring the design to life, ensuring the software is robust,
                      scalable, and secure."
                    </p>
                  </div>
                  <div className="single-item">
                    <div className="count-box">3</div>
                    <h3>Testing and Deployment.</h3>
                    <p>
                      "We test the software for bugs and performance issues,
                      then deploy it to AWS Cloud or Azure or GCP, ensuring
                      smooth operation and optimal performance."
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="how-its-work-image">
                <img src={howItWork} alt="banner" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhyChooseUs;
