import React from "react";

import aboutImage from "../../images/about/about-img5.png";
import starIcon from "../../images/star-icon.png";
import icon4 from "../../images/icons/icon4.png";
import icon5 from "../../images/icons/icon5.png";
import icon6 from "../../images/icons/icon6.png";
import icon7 from "../../images/icons/icon7.png";
import shape1 from "../../images/shape/circle-shape1.png";

const AboutUsContent = () => {
  return (
    <>
      <section className="about-area ptb-100">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="about-image">
                <img src={aboutImage} alt="banner" />
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="about-content">
                <div className="content">
                  <span className="sub-title">
                    <img src={starIcon} alt="banner" />
                    About Us
                  </span>
                  <h2>Introduction</h2>
                  <p>
                    Hello, we are a team of experienced developers based in
                    Mohali, Punjab, India. Our team specializes in Full Stack
                    Development, offering <b>over 10+ years of expertise</b> in
                    creating Web Applications, Mobile Applications, Backend
                    APIs, and AWS Cloud Deployment & Development. We are
                    <b>
                      {" "}
                      certified AWS Developer Associates and AWS Solutions
                      Architects, as well as MongoDB Certified Developer
                      Associates
                    </b>
                    . Our certifications reflect our deep knowledge and
                    commitment to delivering high-quality, scalable solutions
                    that meet the needs of businesses and enhance user
                    experiences across various platforms.
                  </p>

                  <ul className="features-list">
                    <li>
                      <img src={icon4} alt="banner" />
                      <h3>10 Years</h3>
                      <p>On the market</p>
                    </li>
                    <li>
                      <img src={icon5} alt="banner" />
                      <h3>10+</h3>
                      <p>Team members and growing</p>
                    </li>
                    <li>
                      <img src={icon6} alt="banner" />
                      <h3>100%</h3>
                      <p>Satisfaction rate</p>
                    </li>
                    <li>
                      <img src={icon7} alt="banner" />
                      <h3>80%</h3>
                      <p>Senior scientist</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="circle-shape1">
          <img src={shape1} alt="banner" />
        </div>

        <div className="container">
          <div className="about-inner-area">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="about-text">
                  <h3>Our History</h3>
                  <p>
                    We started in 2015, specializing in web and mobile
                    application development, delivering innovative solutions.
                  </p>

                  <ul className="features-list">
                    <li>
                      <i className="flaticon-tick"></i> Activate Listening
                    </li>
                    <li>
                      <i className="flaticon-tick"></i> Brilliant minds
                    </li>
                    <li>
                      <i className="flaticon-tick"></i> Better. Best. Wow!
                    </li>
                    <li>
                      <i className="flaticon-tick"></i> Branding it better!
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="about-text">
                  <h3>Our Mission</h3>
                  <p>
                    Our mission is to help businesses build scalable, secure,
                    and high-performing applications that drive growth and
                    innovation.
                  </p>

                  <ul className="features-list">
                    <li>
                      <i className="flaticon-tick"></i> Creating. Results.
                    </li>
                    <li>
                      <i className="flaticon-tick"></i> Expect more
                    </li>
                    <li>
                      <i className="flaticon-tick"></i> Good thinking
                    </li>
                    <li>
                      <i className="flaticon-tick"></i> In real we trust
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div className="about-text">
                  <h3>Who we are</h3>
                  <p>
                    We are a leading technology solutions provider specializing
                    in web application and mobile application development, REST
                    API integration, and AWS cloud deployment and development.
                  </p>

                  <ul className="features-list">
                    <li>
                      <i className="flaticon-tick"></i> Stay real. Always.
                    </li>
                    <li>
                      <i className="flaticon-tick"></i> We have you covered
                    </li>
                    <li>
                      <i className="flaticon-tick"></i> We turn heads
                    </li>
                    <li>
                      <i className="flaticon-tick"></i> Your brand, promoted
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="circle-shape1">
          <img src={shape1} alt="banner" />
        </div>
      </section>
    </>
  );
};

export default AboutUsContent;
