import React from "react";
import Layout from "../../components/_App/layout";
import Seo from "../../components/_App/seo";
import Navbar from "../../components/_App/Navbar";
import PageBanner from "../../components/Common/PageBanner";
import ServicesOne from "../../components/Services/ServicesOne";
import StartProject from "../../components/Common/StartProject";
import Footer from "../../components/_App/Footer";

const ServicesPage = () => {
  return (
    <Layout>
      <Seo title="Services" />
      <Navbar />

      <PageBanner
        pageTitle="Services"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Services"
      />

      <ServicesOne />

      <StartProject />

      <Footer />
    </Layout>
  );
};

export default ServicesPage;
