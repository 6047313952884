import React from "react";
import { Link } from "gatsby";
import starIcon from "../../images/star-icon.png";
import project1 from "../../images/projects/project1.jpg";
import project2 from "../../images/projects/project2.jpg";
import project3 from "../../images/projects/project3.jpg";
import project4 from "../../images/projects/project4.jpg";
import project5 from "../../images/projects/project5.jpg";
import project6 from "../../images/projects/project6.jpg";

const RecentProjects = () => {
  return (
    <>
      <section className="projects-area bg-color pt-100 pb-70">
        <div className="container">
          <div className="section-title">
            <span className="sub-title">
              <img src={starIcon} alt="project" /> Recent Projects
            </span>
            <h2>Check Some Of Our Recent Work</h2>
            <p>
              Dive into our portfolio to see our recent successes. Each project
              demonstrates our expertise and passion for delivering outstanding
              results
            </p>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-projects-box">
                <div className="image">
                  <img
                    src="https://crew-code-images.s3.us-east-1.amazonaws.com/portfolio.jpeg"
                    alt="project"
                  />

                  <Link
                    className="link-btn"
                    to="/portfolios/portfolio-angular-online-exam-app/"
                  >
                    <i className="bx bx-plus"></i>
                  </Link>
                </div>

                <div className="content">
                  <h3>
                    <Link to="/portfolios/portfolio-angular-online-exam-app/">
                      Exam Crew
                    </Link>
                  </h3>
                  <span>
                    Exam Crew Application is online exam platform with Webcam
                    integration
                  </span>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="single-projects-box">
                <div className="image">
                  <img
                    src="https://crew-code-images.s3.us-east-1.amazonaws.com/portfolio2.jpeg"
                    alt="project"
                  />

                  <Link
                    className="link-btn"
                    to="/case-studies/case-studies-details"
                  >
                    <i className="bx bx-plus"></i>
                  </Link>
                </div>

                <div className="content">
                  <h3>
                    <Link to="/portfolios/portfolio-react-crew-meet">
                      Crew Meet
                    </Link>
                  </h3>
                  <span>
                    Crew Meet is Our real-time video meeting application allows
                    video and audio calls{" "}
                  </span>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="single-projects-box">
                <div className="image">
                  <img
                    src="https://crew-code-images.s3.us-east-1.amazonaws.com/portfolio3.jpeg"
                    alt="project"
                  />

                  <Link
                    className="link-btn"
                    to="/case-studies/case-studies-details"
                  >
                    <i className="bx bx-plus"></i>
                  </Link>
                </div>

                <div className="content">
                  <h3>
                    <Link to="/portfolios/portfolio-react-crewnote/">
                      Crew Note
                    </Link>
                  </h3>
                  <span>
                    Crew Note is a note-taking application ,help users manage
                    their ideas and tasks
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RecentProjects;
