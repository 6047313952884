import React from "react";
import { Link } from "gatsby";
import starIcon from "../../images/star-icon.png";

const OurSolutions = () => {
  return (
    <>
      <section className="solutions-area pb-70">
        <div className="container">
          <div className="section-title">
            <span className="sub-title">
              <img src={starIcon} alt="star" />
              Our Solutions
            </span>
            <h2>Why Choose Us</h2>
            <p>
              You can trust us because we believe in delivering excellence
              through a well-defined and proven process.With us, it’s not just
              about getting the job done
              <br />
              —it’s about doing it right
              <br />
              We always follow three step process for getting any job done
              either small or big job
            </p>
          </div>

          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="single-solutions-box">
                <div className="icon">
                  <i className="flaticon-rocket"></i>
                </div>
                <h3>Planning</h3>
                <p>
                  We work with you to define the software’s architecture, design
                  user-friendly interfaces, and build scalable systems. We also
                  plan resources, set timelines, and assess risks for smooth
                  execution.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="single-solutions-box">
                <div className="icon">
                  <i className="flaticon-laptop"></i>
                </div>

                <h3>Development and Coding</h3>

                <p>
                  In this phase, our team writes clean, efficient code to bring
                  the design to life, ensuring the software is robust, scalable,
                  and secure.
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
              <div className="single-solutions-box">
                <div className="icon">
                  <i className="flaticon-money"></i>
                </div>

                <h3>Testing and Deployment</h3>

                <p>
                  We test the software for bugs and performance issues, then
                  deploy it to AWS Cloud or Azure or GCP, ensuring smooth
                  operation and optimal performance.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurSolutions;
