import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/_App/layout";
import Seo from "../components/_App/seo";
import Navbar from "../components/_App/Navbar";
import PageBanner from "../components/Common/PageBanner";
import Footer from "../components/_App/Footer";

const BlogDetailsContent = ({ data, location }) => {
  const post = data?.markdownRemark;
  const siteTitle = data?.site?.siteMetadata?.title || `Title`;
  const { previous, next } = data;
  //  console.log("BLOG_DATA",data);
  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post?.frontmatter?.title}
        description={post?.frontmatter?.description || post?.excerpt}
      />
      <Navbar />

      <PageBanner
        pageTitle={post?.frontmatter?.title}
        homePageText="Blog Page"
        homePageUrl="/blog"
        activePageText="Blog Details"
      />

      <section className="blog-details-area ptb-70 ptt-20">
        <div className="container">
          <div className="row">
            <div className="blog-details-desc">
              <div className="article-content">
                <h3>{post?.frontmatter?.title}</h3>
                <div
                  style={{ fontSize: "20px" }}
                  dangerouslySetInnerHTML={{ __html: post?.html }}
                  className="article-content"
                />
              </div>

              <div className="tracer-post-navigation">
                <div className="prev-link-wrapper">
                  <div className="info-prev-link-wrapper">
                    {previous && (
                      <Link to={previous?.fields?.slug}>
                        <span className="image-prev">
                          <img
                            src={
                              previous?.frontmatter?.bannerimage ||
                              "/default-image.jpg"
                            }
                            alt={
                              previous?.frontmatter?.title || "Previous post"
                            }
                          />
                          <span className="post-nav-title">Previous</span>
                        </span>

                        <span className="prev-link-info-wrapper">
                          <span className="prev-title">
                            {previous?.frontmatter?.title?.length > 30
                              ? `${previous?.frontmatter?.title.substring(
                                  0,
                                  30
                                )}...`
                              : previous?.frontmatter?.title}
                          </span>
                          <span className="meta-wrapper">
                            {previous?.frontmatter?.date}
                          </span>
                        </span>
                      </Link>
                    )}
                  </div>
                </div>

                <div style={{ flex: "none" }} className="next-link-wrapper">
                  <div className="info-next-link-wrapper">
                    {next && (
                      <Link to={next?.fields?.slug}>
                        <span className="next-link-info-wrapper">
                          <span className="next-title">
                            {next?.frontmatter?.title?.length > 30
                              ? `${next?.frontmatter?.title.substring(
                                  0,
                                  30
                                )}...`
                              : next?.frontmatter?.title}
                          </span>
                          <span className="meta-wrapper">
                            {next?.frontmatter?.date}
                          </span>
                        </span>

                        <span className="image-next">
                          <img
                            src={
                              next?.frontmatter?.bannerimage ||
                              "/default-image.jpg"
                            }
                            alt={next?.frontmatter?.title || "Next post"}
                          />
                          <span className="post-nav-title">Next</span>
                        </span>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </Layout>
  );
};

export default BlogDetailsContent;

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        bannerimage
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        bannerimage
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        bannerimage
      }
    }
  }
`;
