export const menuArray = [
  {
    type: "frontend",
    menus: [
      { name: "Angular", slug: "/services/service-details/frontend/angular" },
      { name: "React", slug: "/services/service-details/frontend/react" },
      { name: "Vue", slug: "/services/service-details/frontend/vue" },
      { name: "Gatsby", slug: "/services/service-details/frontend/gatsby" },
    ],
  },
  {
    type: "backend",
    menus: [
      { name: "Python", slug: "/services/service-details/backend/python" },
      { name: "PHP", slug: "/services/service-details/backend/php" },
      { name: "Node.js", slug: "/services/service-details/backend/nodejs" },
    ],
  },
  {
    type: "cloud",
    menus: [{ name: "AWS", slug: "/services/service-details/cloud/aws" }],
  },
  {
    type: "DataEngineering",
    menus: [
      {
        name: "Amazon Redshift",
        slug: "/services/service-details/DataEngineering/amazon-redshift",
      },
      {
        name: "Apache Kafka",
        slug: "/services/service-details/DataEngineering/apache-kafka",
      },
    ],
  },
  {
    type: "MobileApplication",
    menus: [
      {
        name: "React Native",
        slug: "/services/service-details/MobileApplication/react-native",
      },
    ],
  },
  {
    type: "mobiledevelopment",
    menus: [
      { name: "React Native", slug: "/services/service-details/mobiledevelopment/react-native" },
    ],
  },
];
