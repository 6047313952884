import * as React from "react";
import Layout from "../components/_App/layout";
import Seo from "../components/_App/seo";
import Navbar from "../components/_App/Navbar";
import Banner from "../components/Index/Banner";
import OurSolutions from "../components/Index/OurSolutions";
import OurServices from "../components/Index/OurServices";
import RecentProjects from "../components/Index/RecentProjects";
import Testimonials from "../components/AboutUs/Testimonials";
import ProjectStartArea from "../components/Index/ProjectStartArea";
import Footer from "../components/_App/Footer";

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Navbar />

    <Banner />

    <OurSolutions />

    <OurServices />

    <RecentProjects />

    <Testimonials />

    <ProjectStartArea />

    <Footer />
  </Layout>
);

export default IndexPage;
