import React from "react";
import { Link } from "gatsby";

const TwoColumns = ({ nodes }) => {
  console.log("Props", nodes);

  return (
    <div className="projects-area pt-100 pb-70">
      <div className="container">
        <div className="row">
          {nodes?.map((node, index) => {
            const {
              frontmatter: { title, description, bannerimage },
              fields: { slug },
            } = node;

            return (
              <div className="col-lg-6 col-md-6" key={index}>
                <div className="single-projects-box">
                  <div className="image">
                    <img src={bannerimage} alt={title} />
                    <Link to={`/portfolios${slug}`} className="link-btn">
                      <i className="bx bx-plus"></i>
                    </Link>
                  </div>
                  <div className="content">
                    <h3>
                      <Link to={`/portfolios${slug}`}>{title}</Link>
                    </h3>
                    <span>{description}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TwoColumns;
