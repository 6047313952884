import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/_App/layout";
import Navbar from "../components/_App/Navbar";
import PageBanner from "../components/Common/PageBanner";
import CaseStudiesDetailsContent from "../components/CaseStudiesDetails/CaseStudiesDetailsContent";
import Footer from "../components/_App/Footer";
import Seo from "../components/_App/seo";

const PortfolioPostTemplate = ({ data }) => {
  const post = data?.markdownRemark;

  return (
    <>
      <Layout>
        <Seo title={post?.frontmatter?.shorttitle} />
        <Navbar />

        <PageBanner
          pageTitle={`${post?.frontmatter?.shorttitle}`}
          homePageText="Portfolios"
          homePageUrl="/case-studies"
          activePageText="Portfolio Details"
        />

        <CaseStudiesDetailsContent data={post} />
        <Footer />
      </Layout>
    </>
  );
};

export default PortfolioPostTemplate;

export const pageQuery = graphql`
  query PortfolioPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        shorttitle
        bannerimage
        date(formatString: "MMMM DD, YYYY")
        description
        bigdescription
        features
        techStack
        technologies
        location
        completed
        isGitHubLink
        gitHubLink
        isDemoLink
        demoLink
        images
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;
