import React from "react";
import { useRecoilState } from "recoil";
import { collapsedState } from "../../utils/recoil-atoms";
import { Link } from "gatsby";
import logo from "../../images/taglogo3.png";

const Navbar = () => {
  const [collapsed, setCollapsed] = useRecoilState(collapsedState);

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  React.useEffect(() => {
    let elementId = document.getElementById("navbar");
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        elementId.classList.add("is-sticky");
      } else {
        elementId.classList.remove("is-sticky");
      }
    });
  });

  const classOne = collapsed
    ? "collapse navbar-collapse"
    : "collapse navbar-collapse show";
  const classTwo = collapsed
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right";

  return (
    <>
      <div id="navbar" className="navbar-area">
        <div className="rewy-nav">
          <div className="container-fluid">
            <nav className="navbar navbar-expand-lg navbar-light">
              <Link
                to="/"
                onClick={() => setCollapsed(true)}
                className="navbar-brand"
              >
                <img src={logo} alt="Crewcode" />
              </Link>

              <button
                onClick={toggleNavbar}
                className={classTwo}
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="icon-bar top-bar"></span>
                <span className="icon-bar middle-bar"></span>
                <span className="icon-bar bottom-bar"></span>
              </button>

              <div className={classOne} id="navbarSupportedContent">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link
                      to="/"
                      onClick={() => setCollapsed(true)}
                      className="nav-link"
                    >
                      Home
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/about-us"
                      onClick={() => setCollapsed(true)}
                      className="nav-link"
                    >
                      About Us
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/services"
                      onClick={() => setCollapsed(true)}
                      className="nav-link"
                    >
                      Services
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/case-studies"
                      onClick={() => setCollapsed(true)}
                      className="nav-link"
                    >
                      Portfolios
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="/blog"
                      onClick={() => setCollapsed(true)}
                      className="nav-link"
                    >
                      Blog
                    </Link>
                  </li>
                </ul>
                <div className="nav-item" style={{ padding: "20px" }}>
                  <Link
                    to="/contact"
                    activeClassName="active"
                    onClick={() => setCollapsed(true)}
                    className="default-btn"
                  >
                    <i className="flaticon-right"></i> Get Started <span></span>
                  </Link>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
