import React from "react";
import service1 from "../../images/services/service1.png";
import service2 from "../../images/services/service2.png";
import starIcon from "../../images/star-icon.png";

const OurServices = () => {
  return (
    <>
      {/* Service Left Image Style */}
      <div className="about-area pb-100">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="about-img">
                <img src={service1} alt="service" />
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="about-content">
                <div className="content">
                  <span className="sub-title">
                    <img src={starIcon} alt="icon" /> Services
                  </span>

                  <h2>Cloud Development & Deployment - AWS</h2>
                  <p>
                    We specialize in AWS cloud development and deployment,{" "}
                    <b>
                      with AWS Developer Associate and AWS Solution Architect
                      certification.
                    </b>{" "}
                    Our team has expertise in designing, building, and
                    optimizing scalable applications in the AWS cloud.
                    <br />
                    We are expert in these AWS Services
                  </p>
                  <ul className="about-list mb-0">
                    <li>
                      <i className="flaticon-tick"></i>
                      Amazon EC2
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      Amazon S3
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      Amazon RDS
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      AWS Lambda
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      API Gateway
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      Amazon VPC
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      Amazon CloudFront
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      Amazon DynamoDB
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      Amazon SQS
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      AWS SNS
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      AWS CloudFormation
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      Amazon EKS
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      Terraform
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      Docker
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      CI/CD
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Service Left Image Style */}

      {/* Service Right Image Style */}
      <div className="our-mission-area pb-100">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="our-mission-content">
                <div className="content">
                  <span className="sub-title">
                    <img src={starIcon} alt="icon" /> Services
                  </span>

                  <h2>Web & Mobile Application Development </h2>
                  <p>
                    We are a{" "}
                    <b>certified team of MongoDB Developer Associates</b> with
                    over <b>10+ years of proven experience </b> in crafting
                    scalable, high-performance web , mobile and database
                    solutions.
                    <br />
                    We are expert in following technolgies
                  </p>

                  <ul className="our-mission-list mb-0">
                    <li>
                      <i className="flaticon-tick"></i>
                      Node JS
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      React Development
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      React Native
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      Laravel Development
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      Vue Js Development
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      PHP Development
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      Python Development
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      Angular Development
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      Flask
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      Django
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      Rest API Development
                    </li>
                    <li>
                      <i className="flaticon-tick"></i>
                      Performance monitoring
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="our-mission-image">
                <img src={service2} alt="service" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Service Right Image Style */}
    </>
  );
};

export default OurServices;
