import React from "react";
import { Link, graphql } from "gatsby";
import { useState } from "react";

import user1 from "../../images/user1.png";

const BlogCard = ({ data }) => {
  const { nodes } = data.allMarkdownRemark;
  const categories = [
    ...new Set(nodes.map((post) => post?.frontmatter?.category)),
  ];

  const [selectedCategory, setSelectedCategory] = useState("All");

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const filteredPosts =
    selectedCategory === "All"
      ? nodes
      : nodes.filter(
          (post) => post?.frontmatter?.category === selectedCategory
        );

  return (
    <>
      <div
        className="dropdown"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginRight: "80px",
          marginBottom: "20px",
        }}
      >
        <strong style={{ marginRight: "10px" }}>Sort By-</strong>
        <select
          id="category-select"
          className="custom-dropdown"
          value={selectedCategory}
          onChange={handleCategoryChange}
        >
          <option value="All">Select Category</option>
          {categories.map((category, index) => (
            <option key={index} value={category}>
              {category[0].toUpperCase() + category.slice(1)}
            </option>
          ))}
        </select>
      </div>

      {/* Blog Content Area */}
      <div className="blog-area ptb-70">
        <div className="container">
          <div className="row">
            {filteredPosts.map((post, index) => {
              const { bannerimage, title, date } = post?.frontmatter;
              return (
                <div key={index} className="col-lg-4 col-md-6">
                  <div className="single-blog-post bg-fffbf5">
                    <div className="post-image">
                      <Link to={post.fields.slug}>
                        <img src={bannerimage} alt="Blog post" />
                      </Link>
                    </div>

                    <div className="post-content">
                      <ul className="post-meta d-flex justify-content-between align-items-center">
                        <li>
                          <div className="post-author d-flex align-items-center">
                            <img
                              src={user1}
                              className="rounded-circle"
                              alt="Blog post"
                            />
                            <span>Admin</span>
                          </div>
                        </li>
                        <li>
                          <i className="flaticon-calendar"></i> {date}
                        </li>
                      </ul>
                      <h3>
                        <Link to={post.fields.slug}>{title}</Link>
                      </h3>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogCard;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/blog/**" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          category
          bannerimage
        }
      }
    }
  }
`;
