import React from "react";
import { Link } from "gatsby";
const ServicesOne = () => {
  return (
    <>
      <section className="solutions-area pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-solutions-box">
                <div className="icon">
                  <i className="flaticon-laptop"></i>
                </div>
                <h3>
                  <Link to="service-details/frontend">
                    Frontend Development
                  </Link>
                </h3>
                <p>
                  At CrewCode Solutions, we specialize in creating modern,
                  responsive, and user-friendly web applications that provide
                  seamless and engaging user experiences. Using cutting-edge
                  technologies like React, Angular, Gatsby, Next.js, and Vue.js,
                  we build high-performance, scalable web solutions. Whether
                  SPAs, static sites with Gatsby, or SEO apps with Next.js
                </p>

                <Link
                  className="view-details-btn"
                  to="/services/service-details/frontend"
                >
                  View Details
                </Link>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-solutions-box">
                <div className="icon">
                  <i className="flaticon-laptop"></i>
                </div>

                <h3>
                  <Link to="/services/service-details/backend">
                    Backend Development
                  </Link>
                </h3>

                <p>
                  We specialize in building powerful, secure, and scalable
                  backend systems to support your applications and drive
                  seamless functionality. Using modern technologies like Node.js
                  (including NestJS and Express.js), PHP, and Python, we develop
                  backend solutions that are both reliable and flexible,
                  tailored to your unique business needs. Our team is skilled in
                  creating robust APIs
                </p>

                <Link
                  className="view-details-btn"
                  to="/services/service-details/backend"
                >
                  View Details
                </Link>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-solutions-box">
                <div className="icon">
                  <i className="flaticon-laptop"></i>
                </div>

                <h3>
                  <Link to="/services/service-details/cloud">
                    Cloud Services
                  </Link>
                </h3>

                <p>
                  We provide cloud solutions using AWS to help businesses scale
                  and optimize. Our services include setting up cloud
                  infrastructure, managing EC2, S3, Lambda, and RDS, serverless
                  app development, cloud migration, and containerization with
                  Docker and Kubernetes. We also offer CI/CD setup and
                  performance monitoring for cost efficiency and reliability.
                </p>

                <Link
                  className="view-details-btn"
                  to="/services/service-details/cloud"
                >
                  View Details
                </Link>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-solutions-box">
                <div className="icon">
                  <i className="flaticon-laptop"></i>
                </div>

                <h3>
                  <Link to="/services/service-details/DataEngineering">
                    Data Engineering
                  </Link>
                </h3>

                <p>
                  We specialize in building robust data pipelines and
                  infrastructures to help businesses efficiently collect,
                  process, and analyze large volumes of data. Using modern tools
                  and technologies, we design scalable solutions for data
                  extraction, transformation, and loading (ETL), ensuring your
                  data is organized, accessible, and ready for analysis. Our
                  expertise includes working with databases, data warehouses,
                  and big data frameworks like Apache Hadoop, Spark, and Kafka.
                </p>

                <Link
                  className="view-details-btn"
                  to="/services/service-details/DataEngineering"
                >
                  View Details
                </Link>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="single-solutions-box">
                <div className="icon">
                  <i className="flaticon-laptop"></i>
                </div>

                <h3>
                  <Link to="/services/service-details/MobileApplication">
                    Mobile Application Development
                  </Link>
                </h3>

                <p>
                  At CrewCode Solutions, we specialize in developing
                  high-performance mobile applications using React Native. This
                  powerful framework allows us to create cross-platform apps for
                  both iOS and Android with a single codebase, ensuring faster
                  development and consistent performance across devices. Our
                  team focuses on building intuitive, user-friendly interfaces
                  while delivering seamless functionality and high-speed
                  performance.
                </p>

                <Link
                  className="view-details-btn"
                  to="/services/service-details/MobileApplication"
                >
                  View Details
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServicesOne;
