import React from "react";
import starIcon from "../../images/star-icon.png";
import shape from "../../images/shape/shape1.svg";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";

const Testimonials = () => {
  return (
    <>
      <section className="testimonials-area pt-70 bg-f1f8fb">
        <div className="container">
          <div className="section-title">
            <span className="sub-title">
              <img src={starIcon} alt="about" />
              Testimonials
            </span>
            <h2>What Our Clients are Saying?</h2>
            <p>
              At Crewcode Solutions, our clients praise us for our
              responsiveness, expertise, and the impactful results we deliver.
              Their positive feedback motivates us to keep pushing for
              excellence and empowering businesses with innovative solutions.
            </p>
          </div>

          <Swiper
            navigation={true}
            spaceBetween={30}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
            }}
            autoplay={{
              delay: 6500,
              disableOnInteraction: true,
              pauseOnMouseEnter: true,
            }}
            modules={[Navigation, Autoplay]}
            className="testimonials-slides"
          >
            <SwiperSlide>
              <div className="single-testimonials-item">
                <p>
                  <img
                    src="https://crew-code-images.s3.us-east-1.amazonaws.com/Testimonial1.png"
                    alt="about"
                  />
                </p>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="single-testimonials-item">
                <p>
                  <img
                    src="https://crew-code-images.s3.us-east-1.amazonaws.com/Testimonial2.png"
                    alt="about"
                  />
                </p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>

        <div className="shape-img1">
          <img src={shape} alt="about" />
        </div>
      </section>
    </>
  );
};

export default Testimonials;
