import React from "react";
import Layout from "../../components/_App/layout";
import Seo from "../../components/_App/seo";
import Navbar from "../../components/_App/Navbar";
import PageBanner from "../../components/Common/PageBanner";
import BlogCard from "../../components/BlogContent/BlogCard";
import Footer from "../../components/_App/Footer";
import { graphql } from "gatsby";

const BlogPage = ({ data, location }) => {
  return (
    <Layout>
      <Seo title="Blog" />
      <Navbar />

      <PageBanner
        pageTitle="Blog"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Blog"
      />

      <BlogCard data={data} />

      <Footer />
    </Layout>
  );
};

export default BlogPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/blog/**" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          category
          bannerimage
        }
      }
    }
  }
`;
