import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import starIcon from "../../images/star-icon.png";
import contact from "../../images/contact.png";

const ContactForm = () => {
  const [randomNumbers, setRandomNumbers] = useState({
    num1: Math.floor(Math.random() * 10) + 1,
    num2: Math.floor(Math.random() * 10) + 1,
  });

  const [statusMessage, setStatusMessage] = useState({
    message: "",
    success: false,
  });

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(3, "Name must be at least 3 characters")
      .matches(
        /^[a-zA-Z\s]+$/,
        "Name cannot contain numbers or special characters"
      )
      .required("Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phone_number: Yup.string().matches(
      /^\+?\d{10,15}$/,
      "Invalid phone number"
    ),
    message: Yup.string()
      .min(50, "Message must be at least 50 characters")
      .required("Message is required")
      .test("no-html", "HTML content is not allowed", (value) => {
        const htmlRegex = /<\/?[a-z][\s\S]*>/i;
        return !htmlRegex.test(value || "");
      }),
    captchaAnswer: Yup.number()
      .required("Please solve the question")
      .test(
        "correct-answer",
        "Incorrect answer, please try again",
        (value) => value === randomNumbers.num1 + randomNumbers.num2
      ),
  });

  const handleSubmit = async (values, { resetForm }) => {
    console.log("Values", values);
    const url =
      "https://crew-code-api.netlify.app/.netlify/functions/server/contact";

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      console.log("Response from server:", result);
      setRandomNumbers({
        num1: Math.floor(Math.random() * 10) + 1,
        num2: Math.floor(Math.random() * 10) + 1,
      });

      setStatusMessage({
        message:
          "Message sent successfully, we will get in touch with you shortly!",
        success: true,
      });
      resetForm();
    } catch (error) {
      setStatusMessage({
        message: "Error sending message. Please try again.",
        success: false,
      });
    }
  };

  return (
    <>
      <section className="contact-area pb-100">
        <div className="container">
          <div className="section-title">
            <span className="sub-title">
              <img src={starIcon} alt="contact" />
              Get in Touch
            </span>
            <h2>Ready to Get Started?</h2>
            <p>
              Your email address will not be published. Required fields are
              marked *
            </p>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="contact-image">
                <img src={contact} alt="contact" />
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="contact-form">
                <Formik
                  initialValues={{
                    name: "",
                    email: "",
                    phone_number: "",
                    message: "",
                    captchaAnswer: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ isSubmitting }) => (
                    <Form id="contactForm">
                      <div className="row">
                        <div className="col-lg-12 col-md-6">
                          <div className="form-group">
                            <Field
                              type="text"
                              name="name"
                              className="form-control"
                              placeholder="Your name"
                              autoComplete="off"
                            />
                            <ErrorMessage
                              name="name"
                              component="div"
                              className="error-message"
                            />
                          </div>
                        </div>

                        <div className="col-lg-12 col-md-6">
                          <div className="form-group">
                            <Field
                              type="email"
                              name="email"
                              className="form-control"
                              placeholder="Your email address"
                              autoComplete="off"
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="error-message"
                            />
                          </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                          <div className="form-group">
                            <Field
                              type="text"
                              name="phone_number"
                              className="form-control"
                              placeholder="Your phone number"
                              autoComplete="off"
                            />
                            <ErrorMessage
                              name="phone_number"
                              component="div"
                              className="error-message"
                            />
                          </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                          <div className="form-group">
                            <Field
                              as="textarea"
                              name="message"
                              className="form-control"
                              cols="30"
                              rows="6"
                              placeholder="Write your message..."
                              autoComplete="off"
                            />
                            <ErrorMessage
                              name="message"
                              component="div"
                              className="error-message"
                            />
                          </div>
                        </div>

                        {/* Captcha Section */}
                        <div className="col-lg-12 col-md-12">
                          <div className="form-group">
                            <label>
                              Solve this: {randomNumbers.num1} +{" "}
                              {randomNumbers.num2} =
                            </label>
                            <Field
                              type="number"
                              name="captchaAnswer"
                              className="form-control"
                              placeholder="Your answer"
                              autoComplete="off"
                            />
                            <ErrorMessage
                              name="captchaAnswer"
                              component="div"
                              className="error-message"
                            />
                          </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                          <button
                            type="submit"
                            className="default-btn"
                            disabled={isSubmitting}
                          >
                            <i className="flaticon-tick"></i>
                            Send Message <span></span>
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
                {/* Display status message */}
                {statusMessage.message && (
                  <div
                    style={{ marginTop: "10px" }}
                    className={`status-message ${
                      statusMessage.success ? "success" : "error"
                    }`}
                  >
                    {statusMessage.message}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactForm;
