import React, { useState } from "react";
import ServiceSidebar from "./ServiceSidebar";

const ServiceDetailsContent = ({ data, type, htmlContent }) => {
  const [activeMenu, setActiveMenu] = useState("");
  const [currentImage, setCurrentImage] = useState(null);

  const safeData = data || {};
  console.log("Safe data", safeData);
  const {
    title = "",
    description = "",
    technologies = [],
    applicationAreas = [],
    image = "",
  } = safeData;

  const handleMenuClick = (menuItem) => {
    /*if (menuItem) {
      setActiveMenu(menuItem.name);
      // Update image based on menu item
      const menuImage = technologyImageMapping[menuItem.name.toLowerCase()];
      if (menuImage) {
        setCurrentImage(menuImage);
      }
    }*/
  };

  return (
    <section className="services-details-area ptb-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12">
            {image && (
              <div className="services-details-image">
                <img src={image} alt={title || "Service"} />
              </div>
            )}

            <div className="services-details-desc">
              {title && <span className="sub-title">{title}</span>}

              <h3>What we do?</h3>
              {description && <p>{description}</p>}

              {htmlContent && (
                <div
                  className="service-detail-html-content"
                  dangerouslySetInnerHTML={{ __html: htmlContent }}
                />
              )}

              {Array.isArray(applicationAreas) &&
                applicationAreas.length > 0 && (
                  <>
                    <h3>Area of Expertise</h3>
                    <ul>
                      {applicationAreas.map((area, index) => (
                        <li key={index}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              marginTop: "5px",
                            }}
                          >
                            <strong>{area.name}</strong>
                          </div>
                          <p style={{ marginTop: "5px" }}>{area.icon}</p>
                        </li>
                      ))}
                    </ul>
                  </>
                )}

              {Array.isArray(technologies) && technologies.length > 0 && (
                <>
                  <h3>Technologies That We Use</h3>
                  <ul className="technologies-features">
                    {technologies.map((tech, index) => (
                      <li key={index}>
                        <span>{tech}</span>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          </div>

          <div className="col-lg-4 col-md-12">
            <ServiceSidebar
              type={type || ""}
              activeMenu={activeMenu}
              onMenuClick={handleMenuClick}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceDetailsContent;
