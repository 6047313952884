import React from "react";
import Layout from "../../components/_App/layout";
import Seo from "../../components/_App/seo";
import Navbar from "../../components/_App/Navbar";
import PageBanner from "../../components/Common/PageBanner";
import TwoColumns from "../../components/Studies/TwoColumns";
import Footer from "../../components/_App/Footer";
import { graphql } from "gatsby";
import PortfolioPostTemplate from "../../templates/portfolio";

const CaseStudiesPage = ({ data, location }) => {
  const { nodes } = data?.allMarkdownRemark;

  return (
    <Layout>
      <Seo title="Portfolios" />
      <Navbar />
      <PageBanner
        pageTitle="Portfolios"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Portfolios"
      />
      <TwoColumns nodes={nodes} />

      <Footer />
    </Layout>
  );
};

export default CaseStudiesPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/portfolio/**" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          shorttitle
          description
          category
          bannerimage
        }
      }
    }
  }
`;
