import React from "react";
import { graphql } from "gatsby";
import Navbar from "../components/_App/Navbar";
import PageBanner from "../components/Common/PageBanner";
import Footer from "../components/_App/Footer";
import ServiceDetailsContent from "../components/ServiceDetails/ServiceDetailsContent";
import Layout from "../components/_App/layout";
import Seo from "../components/_App/seo";

const FrontendServicesPage = ({ data, pageContext }) => {
  const { frontmatter, html } = data?.markdownRemark;
  const { type } = pageContext;

  return (
    <div>
      <Layout>
        <Seo
          title={frontmatter?.title}
          description={frontmatter?.description}
        />
        <Navbar />
        <PageBanner
          pageTitle={`Service Details ${type
            .split("/")[2]
            ?.charAt(0)
            ?.toUpperCase()}${type.split("/")[2].slice(1)}`}
          homePageText={`Service Details ${type.split("/")[1]}`}
          homePageUrl={`/services/service-details/${type.split("/")[1]}`}
          activePageText={`Service Details ${type
            .split("/")[2]
            ?.charAt(0)
            ?.toUpperCase()}${type.split("/")[2].slice(1)}`}
        />
        <ServiceDetailsContent
          type={type.split("/")[1]}
          data={frontmatter}
          htmlContent={html}
        />
        <Footer />
      </Layout>
    </div>
  );
};

export const query = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
        image
        type
        technologies
        applicationAreas {
          name
          icon
        }
      }
      html
    }
  }
`;

export default FrontendServicesPage;
