import React from "react";
import { graphql } from "gatsby";
import Footer from "../components/_App/Footer";
import Layout from "../components/_App/layout";
import Navbar from "../components/_App/Navbar";
import PageBanner from "../components/Common/PageBanner";
import ServiceDetailsContent from "../components/ServiceDetails/ServiceDetailsContent";
import Seo from "../components/_App/seo";

const ServiceContent = ({ data, pageContext }) => {
  const { frontmatter } = data?.markdownRemark;
  console.log("Front matter", frontmatter);
  const { type } = pageContext;

  return (
    <>
      <Layout>
        <Seo
          title={frontmatter?.title}
          description={frontmatter?.description}
        />
        <Navbar />
        <PageBanner
          pageTitle={`Service Details ${type
            ?.charAt(0)
            .toUpperCase()}${type?.slice(1)}`}
          homePageText="Services"
          homePageUrl="/services"
          activePageText={`Service Details ${type
            .charAt(0)
            .toUpperCase()}${type.slice(1)}`}
        />
        <ServiceDetailsContent type={type} data={frontmatter} />
        <Footer />
      </Layout>
    </>
  );
};

export const query = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
        image
        type
        technologies
        applicationAreas {
          name
          icon
        }
      }
      html
    }
  }
`;

export default ServiceContent;
